import { IFProjectState } from './InterfacesState';

export const ProjectState: IFProjectState = {
  header: { menu: [] },
  alert: [],
  article: null,
  widthWindow: window.innerWidth,
  offsetContent: 0,
  lang: 'Ru',
  currentCaseIds: null,
};
