import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';

import { Props } from './interfaces';
import './App.scss';
import './App-media.scss';
import Page404 from '../../pages/404/404';
import Main from '../../pages/Main/Main';
import { bodyAuto, htmlNoScroll, htmlScroll } from '../../services/GlobalFunctions';
import PagePolicy from '../../pages/PagePolicy/PagePolicy';
import { EnumUrlsPages as Url, EnumUrlsPagesEn as UrlEn } from '../../services/Enum';
import PageProjects from '../../pages/PageProjects/PageProjects';
import Layout from '../Layout/Layout';
import { Cursor } from '../Cursor/Cursor';
import PageAgency from '../../pages/PageAgency/PageAgency';
import PageCase from '../../pages/PageCase/PageCase';
import PageContacts from '../../pages/PageContacts/PageContacts';
import PageServices from '../../pages/PageServices/PageServices';

const App = ({ alertLoaded, widthWindow, widthWindowLoaded, offsetContentLoaded }: Props) => {
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    widthWindowLoaded(window.innerWidth);

    const block = document.querySelector('.content') as HTMLElement;
    if (block) {
      offsetContentLoaded(block.getBoundingClientRect().x);
    }
  };

  const routes: { path: string; element: JSX.Element }[] = [
    { path: '/', element: <Layout page={<Main />} colorHeader="white" /> },
    { path: '/en', element: <Layout page={<Main />} colorHeader="white" /> },

    { path: Url.projects, element: <Layout page={<PageProjects />} noFooter colorHeader="white" /> },
    { path: UrlEn.projects, element: <Layout page={<PageProjects />} noFooter colorHeader="white" /> },

    { path: `${Url.case}/:id`, element: <Layout page={<PageCase />} noHeader /> },
    { path: `${UrlEn.case}/:id`, element: <Layout page={<PageCase />} noHeader /> },

    { path: Url.services, element: <Layout page={<PageServices />} /> },
    { path: UrlEn.services, element: <Layout page={<PageServices />} /> },

    { path: Url.agency, element: <Layout page={<PageAgency />} colorHeader="white" /> },
    { path: UrlEn.agency, element: <Layout page={<PageAgency />} colorHeader="white" /> },

    { path: Url.contacts, element: <Layout page={<PageContacts />} noFooter /> },
    { path: UrlEn.contacts, element: <Layout page={<PageContacts />} noFooter /> },

    { path: Url.policy, element: <Layout page={<PagePolicy />} /> },
    { path: UrlEn.policy, element: <Layout page={<PagePolicy />} /> },
  ];

  return (
    <Router>
      <div className="app">
        <ScrollToTop />

        <Routes>
          {routes.map(({ path, element }, i) => (
            <Route path={path} element={element} />
          ))}

          <Route path="*" element={<Layout page={<Page404 />} />} />
        </Routes>
      </div>
    </Router>
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.querySelectorAll('a').forEach((a) => {
      a.addEventListener('click', () => {
        if (!a.getAttribute('href')?.includes('#')) {
          htmlNoScroll();
        }
      });
    });

    window.scrollTo(0, 0);
    htmlScroll();
  }, [pathname]);

  bodyAuto();

  return null;
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(App));
