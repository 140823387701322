import React from 'react';
import './404.scss';
import './404-media.scss';
import Button from '../../components/Button/Button';

const Page404 = () => {
  return (
    <div className="page-404">
      <div className="page-404__content">
        <div className="page-404__bg" />
        <div className="page-404__blocks">
          <h2 className="page-404__title">404</h2>
          <p className="body1 page-404__text">Страница не найдена</p>
          <Button text="Вернуться на главную" link="/" />
        </div>
      </div>
    </div>
  );
};

export default Page404;
