import React from 'react';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import { Swiper, SwiperSlide } from '../../services/ImportsSwiper';
import { Props } from './interfaces';
import './SliderRunner.scss';
import './SliderRunner-media.scss';
import { settings } from './constants';

const SliderRunner = ({ slidesTexts, widthWindow }: Props) => {
  return (
    <div className="block-logos">
      {slidesTexts.length ? (
        <Swiper
          {...settings}
          slidesPerView={Math.ceil(widthWindow / 280)}
          spaceBetween={widthWindow > 720 ? 40 : 8}
        >
          {slidesTexts.map((text, i) => (
            <SwiperSlide key={i}>{text}</SwiperSlide>
          ))}
        </Swiper>
      ) : null}
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(SliderRunner));
