import * as React from 'react';
import './TextImageTwoColumn.scss';
import {baseURLModx} from '../../redux/services/APIRequestsModx';

type TextPropsType = {
  text: string;
  title: string;
  image: string;
};

type TextTwoColumnPropsType = {
  title?: string;
  subtitle?: string;
  text?: Array<TextPropsType>;
};

export function TextImageTwoColumn({title, subtitle, text}: TextTwoColumnPropsType) {
  return (
    <div className="text-image-column content">
      <div className="text-image-column__container">
        <div className="text-image-column__head">
          <h2 className="text-image-column__title">{title}</h2>
          <p className="text-image-column__subtitle">{subtitle}</p>
        </div>

        <div className="text-image-column__grid">
          {text?.map((item, i) => (
            <div className="text-image-column__item" key={i}>
              <div className="text-image-column__col">
                <h5 className="text-image-column__title">{item.title}</h5>
                <div className="text-image-column__text" dangerouslySetInnerHTML={{__html: item.text}}></div>
              </div>

              <div className="text-image-column__col">
                <img src={`${baseURLModx + '/' + item.image}`} alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
