import React, { useEffect, useState } from 'react';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import { EnumUrlsPages } from '../../services/Enum';
import './Footer.scss';
import './Footer-media.scss';
import Button from '../Button/Button';
import Tel from '../Tel/Tel';
import MailTo from '../MailTo/MailTo';
import { Socs } from './constants';
import BaseLink from '../BaseLink/BaseLink';
import { ServiceData } from '../../redux/services/ServiceRedux';
import { Props } from './interfaces';
import { IModxContactsPage } from '../../redux/services/InterfacesModx';
import { STUB_CONTACTS } from '../../pages/PageContacts/constants';

const Footer = ({ lang, alertLoaded }: Props) => {
  const [data, setData] = useState<IModxContactsPage>(STUB_CONTACTS);
  const {
    object: {
      contacts_block: { address, copyright },
    },
  } = data;
  useEffect(() => {
    ServiceData.getContactsPage({ alertLoaded, success, en: lang === 'En' });
  }, [lang]);

  const success = (data: IModxContactsPage) => {
    setData(data);
  };

  return (
    <footer className="footer">
      <div className="footer__block1">
        <h4>So. Let's research, create, launch and promote</h4>
        <Button text={lang === 'En' ? 'Start' : 'Старт'} type="border" link={EnumUrlsPages.contacts} />
      </div>

      <div className="footer__block2">
        <div>
          <Tel phone="+7 (495) 269-03-17" className="body1" />
          <MailTo mail="target@ra-tm.ru" className="body1" />
        </div>

        <div className="footer__socs">
          {Socs.map(({ text, link }, i) => (
            <BaseLink link={link} text={text} out key={i} />
          ))}
        </div>
      </div>

      <div className="footer__block3">
        <p className="body2">{address}</p>
      </div>

      <div>
        <a href={EnumUrlsPages.policy} className="body2 footer__policy">
          {lang === 'En' ? 'Policy' : 'Политика конфиденциальности'}
        </a>
        <p className="body2">{copyright}</p>
      </div>
    </footer>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(Footer));
