import React, {useEffect, useState} from 'react';
import {connect, WithStore, MapStateToProps, MapDispatchToProps} from '../../redux/services/Imports';
import './PageAgency.scss';
import './PageAgency-media.scss';
import SliderGallery from '../../components/SliderGallery/SliderGallery';
import {Statistic, StatisticEn, STUB_AGENCY} from './constants';
import ContentForMedia from '../../components/ContentForMedia/ContentForMedia';
import Button from '../../components/Button/Button';
import Content from '../../components/Content/Content';
import SliderLogos from '../../components/SliderLogos/SliderLogos';
import Counter from '../../components/Counter/Counter';
import Title from '../../components/Title/Title';
import {EnumUrlsPages} from '../../services/Enum';
import SliderTeam from '../../components/SliderTeam/SliderTeam';
import {IModxAgencyPage} from '../../redux/services/InterfacesModx';
import {ServiceData} from '../../redux/services/ServiceRedux';
import {Props} from './interfaces';
import {formatGallery, formatLogos, formatTeam} from './functions';
import DocumentMeta from 'react-document-meta';
import {baseURLModx} from '../../redux/services/APIRequestsModx';

const PageAgency = ({lang, alertLoaded, widthWindow}: Props) => {
  const [statistic, setStatistic] = useState(Statistic);
  const [data, setData] = useState<IModxAgencyPage>(STUB_AGENCY);
  const {
    object: {
      main_screen: {title: adminMainTitle, description, main_button_text, background},
      clients_block: {title: adminClientsTitle},
      team_block: {team, description: teamDescription},
      logo,
      gallery,
      seo,
    },
  } = data;

  useEffect(() => {
    ServiceData.getAgencyPage({alertLoaded, success, en: lang === 'En'});
    setStatistic(lang === 'En' ? StatisticEn : Statistic);
  }, [lang]);

  const success = (data: IModxAgencyPage) => {
    setData(data);
  };

  return (
    <div className="page-agency">
      <DocumentMeta {...seo} />

      <div className="page-agency__main">
        <div className="page-agency__main-bg" style={background ? {backgroundImage: `url(${baseURLModx}/${background})`} : {}}></div>
        <ContentForMedia title={adminMainTitle} text={description} buttonsBlock={<Button text={main_button_text} link={EnumUrlsPages.contacts} />} mb="40px" />
      </div>

      <Content padding={{paddingMain: {top: 160, bottom: 0}, paddingMob: {top: 40, bottom: 0}}} block={<Title title={adminClientsTitle} color="dark" marginBottom={60} />} />

      <div className="page-agency__slider-logos">
        <SliderLogos padding="0" logosPartners={formatLogos(logo)} />
      </div>

      <Content
        padding={{paddingMain: {top: 80, bottom: 80}, paddingMob: {top: 40, bottom: 40}}}
        block={
          <div className="page-agency__statistic">
            {statistic.map((item, i) => (
              <Counter {...item} key={i} />
            ))}
          </div>
        }
      />

      <Content padding={{paddingMain: {top: 80, bottom: 80}, paddingMob: {top: 40, bottom: 60}}} block={<Title title={lang === 'En' ? 'Team' : 'Команда'} color="dark" shortText={teamDescription} />} />
      <SliderTeam slides={formatTeam(team)} />

      <SliderGallery slides={gallery} countDotsSlides={8} />
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(PageAgency));
