import React from 'react';
import './ErrorText.scss';
import './ErrorText-media.scss';

const ErrorText = (serverText: string, server?: boolean) => {
  return (
    <div className="error">
      <p
        className={`error__text${server ? ' error__text_server' : ''}`}
        dangerouslySetInnerHTML={{ __html: serverText }}
      ></p>
    </div>
  );
};

export default ErrorText;
