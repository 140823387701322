import { TextBlock } from '../cases/TextBlock/TextBlock';
import { IframeVideo } from '../components/BlockVideo/IframeVideo';
import { FullScreenImage } from '../cases/FullScreenImage/FullScreenImage';
import SliderGallery from '../components/SliderGallery/SliderGallery';
import { TextTwoColumn } from '../cases/TextTwoColumn/TextTwoColumn';
import SliderVideo from '../cases/SliderVideo/SliderVideo';
import { TextTwoColumnIcon } from '../cases/TextTwoColumnIcon/TextTwoColumnIcon';
import { BlackBgSection } from '../cases/BlackBgSection';
import { BlackBgSectionCards } from '../cases/BlackBgSectionCards';
import { NumbersGrid } from '../cases/NumbersGrid';
import ThreeColumImages from '../cases/ThreeColumGallery/ThreeColumGallery';
import { TextImageTwoColumn } from '../cases/TextImageTwoColumn';
import { SocialBlock } from '../cases/SocialBlock';

export const ConstructorCases = ({ nameBlock, content }: { nameBlock: string; content: any }) => {
  switch (nameBlock) {
    case 'Текст': {
      return <TextBlock text={content.text} />;
    }
    case 'Код для вставки видео': {
      return <IframeVideo iframe={content.video_code} />;
    }
    case 'Изображение на весь экран': {
      return <FullScreenImage image={content.image} />;
    }
    case 'Слайдер изображений': {
      return <SliderGallery countDotsSlides={8} slides={content.slider} title={content.title} subtitle={content.subtitle} />;
    }
    case 'Заголовок H3 и подзаголовок и текст справа': {
      return <TextTwoColumn title={content.title} subtitle={content.subtitle} text={content.text} />;
    }
    case 'Слайдер из тизеров с кнопкой (видеоролики + кнопка)': {
      return (
        <SliderVideo
          title={content.title}
          subtitle={content.subtitle}
          slides={content.video_slider}
        />
      );
    }
    case 'Заголовок H3 и подзаголовок и n-абзацев текста справа разделенных': {
      return <TextTwoColumnIcon title={content.title} subtitle={content.subtitle} text={content.text} />;
    }
    case 'Заголовок H2 + подзаголовок + заливка + 4 блока текста': {
      return (
        <BlackBgSectionCards
          title={content.title}
          subtitle={content.subtitle}
          text={content.text}
          color={content.color}
        />
      );
    }
    case 'Заголовок H2 + подзаголовок + цифра показателей + описание в пару слов (n-штук)': {
      return <NumbersGrid title={content.title} subtitle={content.subtitle} text={content.text} />;
    }
    case 'Изображение паралакс + цвет фона + Заголвок H2 + подзаголовок + Список тезисов': {
      return (
        <BlackBgSection
          title={content.title}
          image1={content.image1}
          subtitle={content.subtitle}
          text={content.text}
          colour={content.colour}
        />
      );
    }
    case '3 картинки размера сторис в ряд': {
      return <ThreeColumImages image1={content.image1} image2={content.image2} image3={content.image3} />;
    }
    case 'Заголовок H2 + подзаголовок + 2 строки из заголовков H5 текста слева и картинки': {
      return <TextImageTwoColumn title={content.title} subtitle={content.subtitle} text={content.text} />;
    }
    case 'Текст+Соцсети': {
      return <SocialBlock text={content.text} socials={content.socials} />;
    }
    default:
      return null;
  }
};
