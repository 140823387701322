import * as React from 'react';
import './TextTwoColumn.scss';

type TextTwoColumnPropsType = {
  title?: string;
  subtitle?: string;
  text?: any;
};

export function TextTwoColumn({title, subtitle, text}: TextTwoColumnPropsType) {
  return (
    <div className="text-column content">
      <div className="text-column__container">
        <div className="text-column__row">
          <div className="text-column__col">
            <h3 className="text-column__title">{title}</h3>
            <p className="text-column__subtitle">{subtitle}</p>
          </div>
          <div className="text-column__text">
            <div className="text-column__item" dangerouslySetInnerHTML={{__html: text}}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
