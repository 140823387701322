import * as React from 'react';
import './SocialBlock.scss';
import {baseURLModx} from '../../redux/services/APIRequestsModx';

type TextPropsType = {
  text: string;
  icon: string;
};

type TextTwoColumnPropsType = {
  text?: string;
  socials?: Array<TextPropsType>;
};

export function SocialBlock({text, socials}: TextTwoColumnPropsType) {
  return (
    <div className="social-section content">
      <div className="social-section__container">
        <div className="social-section__head">
          <h3 className="social-section__title">{text}</h3>
        </div>
        {socials ? (
          <div className="social-section__list">
            {socials.map((social, i) => (
              <div className="social-section__item" key={i}>
                <a href={social.text} target="_blank">
                  <img src={`${baseURLModx}/${social.icon}`} alt="" />
                </a>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}
