import React, { useState } from 'react';
import './Cookies.scss';
import './Cookies-media.scss';
import Button from '../Button/Button';
import { CookieFlag } from '../../services/Constants';

const Cookies = () => {
  const hasAccept = localStorage.getItem(CookieFlag);
  const [show, setShow] = useState<boolean>(!hasAccept);

  const acceptCookies = () => {
    setShow(false);
    localStorage.setItem(CookieFlag, 'true');
  };

  return show ? (
    <div className="cookies">
      <p>
        Мы, как и все, используем <span>Cookie файлы</span> для улучшения работы сайта и аналитики. Вы можете
        запретить их использование в настройках браузера.
      </p>
      <Button text="Принять" size="M" onClick={acceptCookies} />
    </div>
  ) : null;
};

export default Cookies;
