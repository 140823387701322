import { useEffect, useState } from 'react';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import './Projects.scss';
import './Projects-media.scss';
import ScrollSpace from '../../components/ScrollSpace/ScrollSpace';
import Tabs from '../../components/Tabs/Tabs';
import { Props } from './interfaces';
import { MediaContent } from '../../services/GlobalFunctions';
import CardLink from '../../components/CardLink/CardLink';

const Projects = ({ list, filtersIndustry, filtersType }: Props) => {
  const [projects, setProjects] = useState(list);
  const [industry, setIndustry] = useState<string>(filtersIndustry[0].tab || '');
  const [type, setType] = useState<string>(filtersType[0].tab || '');

  const SetIndustry = (tab: string) => setIndustry(tab);
  const SetType = (tab: string) => setType(tab);

  useEffect(() => {
    let arr = [...list];

    if (industry !== 'all-industry') {
      arr = arr.filter((x) => x.industries.includes(industry));
    }

    if (type !== 'all-type') {
      arr = arr.filter((x) => x.services.includes(type));
    }

    setProjects(arr);
  }, [type, industry, list]);

  return (
    <div className="projects">
      {MediaContent(
        <div className="projects__space">
          <ScrollSpace list={projects} columns={5} />
        </div>,
        <div className="projects__list-mob">
          {projects.map((item, i) => (
            <CardLink {...item} key={i}></CardLink>
          ))}
        </div>
      )}

      <div className="projects__filters">
        <div className="projects__filters-item">
          <Tabs list={filtersIndustry} itemTab={industry} onChange={SetIndustry} />
        </div>
        <div className="projects__filters-item">
          <Tabs list={filtersType} itemTab={type} onChange={SetType} />
        </div>
      </div>
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(Projects));
