export enum EnumUrlsPages {
  projects = '/projects',
  case = '/case',
  services = '/services',
  agency = '/agency',
  contacts = '/contacts',
  policy = '/policy',
}

export enum EnumUrlsPagesEn {
  projects = '/en/projects',
  case = '/en/case',
  services = '/en/services',
  agency = '/en/agency',
  contacts = '/en/contacts',
  policy = '/en/policy',
}
