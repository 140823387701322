import React from 'react';
import './MenuHamburger.scss';
import './MenuHamburger-media.scss';
import { Props } from './interfaces';

const MenuHamburger = ({ onClick }: Props) => {
  return (
    <div className="menu-hamburger">
      <input className="menu-hamburger__checkbox" type="checkbox" onChange={onClick} />
      <span className="menu-hamburger__line line1"></span>
      <span className="menu-hamburger__line line2"></span>
      <span className="menu-hamburger__line line3"></span>
    </div>
  );
};

export default MenuHamburger;
