import { bindActionCreators } from 'redux';
import {
  AlertLoaded,
  ArticleLoaded,
  CurrentCaseIdsLoaded,
  LangLoaded,
  OffsetContentLoaded,
  WidthWindowLoaded,
} from '../actions/Actions';

export const MapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      alertLoaded: AlertLoaded,
      articleLoaded: ArticleLoaded,
      widthWindowLoaded: WidthWindowLoaded,
      offsetContentLoaded: OffsetContentLoaded,
      langLoaded: LangLoaded,
      currentCaseIdsLoaded: CurrentCaseIdsLoaded,
    },
    dispatch
  );
};
