import { IModxServicesPage } from '../../redux/services/InterfacesModx';

export const STUB_SERVICES: IModxServicesPage = {
  object: {
    id: '',
    seo: {
      title: '',
      description: '',
    },
    services_block: {
      title: '',
      services: [],
    },
  },
};
