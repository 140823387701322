import React, { RefObject, createRef } from 'react';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import { Props } from './interfaces';
import './Button.scss';
import './Button-media.scss';
import { Link } from 'react-router-dom';

const Button = ({
  text,
  size,
  type,
  color = 'dark',
  link,
  widthAll,
  onClick,
  disabled,
  title,
  BaseLink = false,
  icon,
  showBlockedError = false,
}: Props) => {
  const elLink: RefObject<HTMLAnchorElement> = createRef();
  const elButton: RefObject<HTMLButtonElement> = createRef();
  const elSpan: RefObject<HTMLSpanElement> = createRef();

  const className =
    'button' +
    `${size ? ` button_${size}` : ' button_L'}` +
    `${type ? ` button_type-${type}` : ''}` +
    `${color && type === 'border' ? ` button_${color}` : ''}` +
    `${widthAll ? ` button_width-all` : ''}` +
    `${disabled ? ` button_disabled` : ''}` +
    `${icon ? ` button_icon` : ''}`;

  if (BaseLink) {
    return (
      <a
        href={link}
        className={className}
        onClick={onClick}
        target="_blank"
        rel="nofollow"
        ref={elLink}
        onMouseEnter={HoverButton(elLink, elSpan)}
        onMouseOut={HoverButton(elLink, elSpan)}
      >
        {text}
        <span ref={elSpan}></span>
      </a>
    );
  }

  return link ? (
    <Link
      to={link}
      className={className}
      onClick={onClick}
      ref={elLink}
      onMouseEnter={HoverButton(elLink, elSpan)}
      onMouseOut={HoverButton(elLink, elSpan)}
    >
      {text}
      <span ref={elSpan}></span>
    </Link>
  ) : (
    <button
      type="submit"
      className={className}
      onClick={onClick}
      disabled={disabled || showBlockedError}
      title={title}
      ref={elButton}
      onMouseEnter={HoverButton(elButton, elSpan)}
      onMouseOut={HoverButton(elButton, elSpan)}
    >
      {text}
      <span ref={elSpan}></span>
    </button>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(Button));

export const HoverButton = (button: RefObject<any>, span: RefObject<any>) => (e: any) => {
  let Top = button.current?.getBoundingClientRect().top;
  let Left = button.current?.getBoundingClientRect().left;

  if (Top && Left) {
    let relX = e.clientX - Left;
    let relY = e.clientY - Top;
    span.current.style.top = `${relY}px`;
    span.current.style.left = `${relX}px`;
  }
};
