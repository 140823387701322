import React, { Fragment } from 'react';
import Media from 'react-media';
import { IValidationFormItems } from './Interfaces';

export const bodyHidden = () => {
  document.querySelector('body')?.classList.add('body-hidden');
};
export const bodyAuto = () => {
  document.querySelector('body')?.classList.remove('body-hidden');
};
export const htmlScroll = () => {
  setTimeout(() => document.querySelector('html')?.classList.add('scroll'), 1000);
};
export const htmlNoScroll = () => {
  document.querySelector('html')?.classList.remove('scroll');
};

export const MediaContent = (
  contentLarge: React.ReactElement | null,
  contentMedium: React.ReactElement | null
) => {
  return (
    <Media
      queries={{
        medium: 'screen and (max-width: 720px)',
        large: 'screen and (min-width: 721px)',
      }}
    >
      {(matches) => (
        <Fragment>
          {matches.medium && contentMedium}
          {matches.large && contentLarge}
        </Fragment>
      )}
    </Media>
  );
};

export const ValidationFormItems = ({ fields, arrSetError, phone, setErrorPhone }: IValidationFormItems) => {
  if (fields && arrSetError) {
    fields.forEach((item, i) => {
      arrSetError[i](null);
    });
  }
  setErrorPhone(null);

  let err = 0;

  if (fields && arrSetError) {
    fields.forEach((item, i) => {
      if (item.length === 0) {
        arrSetError[i]('Пожалуйста, заполните поле');
        err = 1;
      }
    });
  }

  if (phone && (phone === '+7 (___) ___-__-__' || phone.replace(/[^0-9]/g, '').length < 11)) {
    setErrorPhone('Пожалуйста, заполните поле');
    err = 1;
  }

  if (err) {
    return false;
  }

  if (fields && arrSetError) {
    fields.forEach((item, i) => {
      arrSetError[i](null);
    });
  }
  setErrorPhone(null);
  return true;
};

export const AdaptiveValue = ({ widthWindow, value }: { widthWindow: number; value: string }) => {
  if (widthWindow > 720 && widthWindow < 1440 && value.slice(-2) === 'px') {
    return `${parseInt(value.slice(0, -2)) / 14.4}vw`;
  }

  return value;
};
