import React, { useEffect, useState } from 'react';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import './Content.scss';
import './Content-media.scss';
import { Props } from './interfaces';
import LazyLoad from 'react-lazy-load';
import { useLocation } from 'react-router';
import { AdaptiveValue } from '../../services/GlobalFunctions';

const Content = ({
  block,
  padding,
  width,
  bgOut,
  bgInto,
  noLazy = false,
  heightLazy,
  widthWindow,
}: Props) => {
  useEffect(() => {
    setPadding(widthWindow > 720 ? paddingMain : paddingMob);
  }, [widthWindow]);

  const { paddingMain, paddingMob } = padding || {
    paddingMain: { top: 0, bottom: 0 },
    paddingMob: { top: 0, bottom: 0 },
  };
  const [{ top, bottom }, setPadding] = useState(widthWindow > 720 ? paddingMain : paddingMob);

  const padd =
    widthWindow > 720 && widthWindow < 1440 && padding
      ? { padding: `${top / 14.4}vw 0 ${bottom / 14.4}vw` }
      : { padding: `${top}px 0 ${bottom}px` };
  const w = width ? { width: AdaptiveValue({ widthWindow, value: width }) } : {};

  const content = (
    <>
      {bgOut ? bgOut : null}
      <div className="content" style={{ ...padd, ...w }}>
        {bgInto ? bgInto : null}
        {block}
      </div>
    </>
  );

  const GetHeight = heightLazy
    ? widthWindow > 720 && widthWindow < 1440
      ? `${heightLazy / 14.4}vw`
      : `${heightLazy}px`
    : '500px';

  const [height, setHeight] = useState(GetHeight);
  useEffect(() => {
    if (heightLazy) {
      setHeight(GetHeight);
    }
  }, [widthWindow]);

  const location = useLocation();
  const [urlParams, setUrlParams] = useState(new URLSearchParams(location.search));
  useEffect(() => setUrlParams(new URLSearchParams(location.search)), [location.search]);

  return noLazy || urlParams.has('ssrLazyVisible') ? (
    content
  ) : (
    <LazyLoad height={height} threshold={0.3} className={heightLazy ? '' : 'lazy-auto'}>
      {content}
    </LazyLoad>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(Content));
