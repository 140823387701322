import React from 'react';
import { Props } from './interfaces';
import './Input.scss';
import './Input-media.scss';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import ErrorText from '../ErrorText/ErrorText';
import { GetMarginTop } from './functions';

const Input = ({
  type = 'text',
  name,
  placeholder = '',
  value,
  label,
  onChange,
  onClick,
  focus = false,
  error,
  widthWindow,
  marginTop = 40,
}: Props) => {
  return (
    <div className="input">
      {label ? (
        <p className="input__label" style={{ marginTop: GetMarginTop({ widthWindow, marginTop }) }}>
          {label}
        </p>
      ) : null}
      <input
        type={type}
        name={name}
        className={`input__field${error ? ' input__field_error' : ''}`}
        placeholder={placeholder}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => (onChange ? onChange(e) : null)}
        onClick={onClick}
        autoFocus={focus}
        style={!label ? { marginTop: GetMarginTop({ widthWindow, marginTop }) } : {}}
      />
      {error ? <span className="input__error"></span> : null}
      {error ? ErrorText(error) : null}
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(Input));
