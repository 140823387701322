import { RefObject } from 'react';

interface IHandlerEnded {
  player: RefObject<HTMLVideoElement>;
  setPlay: any;
  setSettingsAutoPlay: any;
  poster: string;
}

interface IHandlerPlayPause {
  player: RefObject<HTMLVideoElement>;
  setPlay: any;
  play: boolean;
}

export const HandlerPlayPauseVideo =
  ({ play, player, setPlay }: IHandlerPlayPause) =>
  () => {
    if (play) {
      player.current?.pause();
      setPlay(false);
    } else {
      player.current?.play();
      setPlay(true);
    }
  };

export const HandlerEndedVideo =
  ({ player, setPlay, setSettingsAutoPlay, poster }: IHandlerEnded) =>
  () => {
    if (player.current) {
      player.current.currentTime = 0;
      setPlay(false);
      setSettingsAutoPlay(false);
      player.current.load();
      player.current.poster = poster;
    }
  };
