import React from 'react';
import { Props } from './interfaces';
import './Pagination.scss';

const Pagination = ({ current, all, small }: Props) => {
  return small ? (
    <h4 className="pagination">{`${current}/${all}`}</h4>
  ) : (
    <h3 className="pagination">{`${current}/${all}`}</h3>
  );
};

export default Pagination;
