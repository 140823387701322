import React, { RefObject, createRef } from 'react';
import './Tabs.scss';
import './Tabs-media.scss';
import { Props } from './interfaces';
import { HoverButton } from '../Button/Button';

const Tabs = ({ list, itemTab, onChange, noHover = false }: Props) => {
  const OnChangeCategory = (category: string | null) => () => onChange(category);

  return (
    <div className="tabs">
      {list.map(({ text, tab }, i) => {
        const elLink: RefObject<HTMLDivElement> = createRef();
        const elSpan: RefObject<HTMLSpanElement> = createRef();

        return (
          <div
            className={`buttonM tabs__item${itemTab === tab ? ' tabs__item_active' : ''}${
              noHover ? ' tabs__item_no-hover' : ''
            }`}
            onClick={OnChangeCategory(tab)}
            key={i}
            ref={elLink}
            onMouseEnter={HoverButton(elLink, elSpan)}
            onMouseOut={HoverButton(elLink, elSpan)}
          >
            {text}
            <span ref={elSpan}></span>
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
