import { IModxAgencyPage } from '../../redux/services/InterfacesModx';

export const Statistic = [
  { count: 2001, prefix: '<span>c </span>', text: 'года на рынке рекламных услуг' },
  { count: 9, suffix: '<span>/10</span>', text: 'баллов в рейтинге надежности AdIndex' },
  { count: 1000, suffix: '<span>+</span>', text: 'проектов с 2014 года' },
  { count: 2018, prefix: '<span>c </span>', text: 'года член АКАР' },
  { count: 10, suffix: '<span>+</span>', text: 'лет средний стаж сотрудников' },
  { count: 2009, prefix: '<span>c </span>', text: 'года в рейтингах AdIndex и Sostav' },
];

export const StatisticEn = [
  {
    count: 2001,
    prefix: '<span>in </span>',
    text: 'year of beginning of work in the advertising services market',
  },
  { count: 9, suffix: '<span>/10</span>', text: 'points in AdIndex reliability rating' },
  { count: 1000, suffix: '<span>+</span>', text: 'projects completed since 2014' },
  { count: 2018, prefix: '<span>in </span>', text: 'we became a member of ACAR' },
  { count: 10, suffix: '<span>+</span>', text: 'years average length of service of employees' },
  { count: 2009, prefix: '<span>in </span>', text: 'we were placed in AdIndex and Sostav' },
];

export const STUB_AGENCY: IModxAgencyPage = {
  object: {
    id: '',
    seo: {
      title: '',
      description: '',
    },
    main_screen: {
      title: '',
      description: '',
      main_button_text: '',
      background: '',
    },
    clients_block: {
      title: '',
      logos: [],
    },
    team_block: {
      description: '',
      team: [],
    },
    gallery: [],
    logo: [],
  },
};
