import React, { useEffect, useState } from 'react';
import { AccordionItem, useAccordionProvider, ControlledAccordion } from '@szhsin/react-accordion';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import './Accordion.scss';
import './Accordion-media.scss';
import { IAccordion } from './interfaces';
import BaseLink from '../BaseLink/BaseLink';

const Accordion = ({ title, link, textLink, content, defaultOpen = false }: IAccordion) => {
  useEffect(() => {
    if (defaultOpen) toggle('acc');
  }, []);

  const [open, setOpen] = useState(false);

  const providerValue = useAccordionProvider({
    allowMultiple: true,
    transition: true,
    transitionTimeout: 250,
  });
  const { toggle } = providerValue;
  const Toggle = (e: any) => {
    if (
      e.target.localName !== 'a' &&
      e.target.localName !== 'p' &&
      e.target.localName !== 'h3' &&
      !e.target.classList.contains('accordion__expend')
    ) {
      setOpen(!open);
      toggle('acc');
    }
  };

  return (
    <div className="accordion" onClick={Toggle}>
      <ControlledAccordion providerValue={providerValue}>
        <AccordionItem
          itemKey="acc"
          header={
            <>
              <h3 className="accordion__title" dangerouslySetInnerHTML={{ __html: title }}></h3>
              <div className="accordion__expend" />
            </>
          }
        >
          <p className="body1 accordion__text" dangerouslySetInnerHTML={{ __html: content }}></p>
          {link ? (
            <div className="accordion__link">
              <BaseLink link={link} text={textLink} />
            </div>
          ) : null}
        </AccordionItem>
      </ControlledAccordion>
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(Accordion));
