import React from 'react';
import './PagePolicy.scss';
import './PagePolicy-media.scss';
import { MediaContent } from '../../services/GlobalFunctions';
import Content from '../../components/Content/Content';
import DocumentMeta from 'react-document-meta';
import { MetaTags } from '../../services/Constants';

const PagePolicy = () => {
  const content = (
    <div className="text-content page-policy">
      <DocumentMeta {...MetaTags.policy} />

      <h1>Политика в отношении обработки персональных данных</h1>
      <p>
        Данная политика конфиденциальности относится к сайту под доменным именем{' '}
        <a href="https://dalli-service.com">dalli-service.com</a> Эта страница содержит сведения о том, какую
        информацию мы (администрация сайта) или третьи лица могут получать, когда вы пользуетесь нашим сайтом.
      </p>

      <h3>Данные, собираемые при посещении сайта</h3>
      <h4>Персональные данные</h4>
      <p>
        Персональные данные при посещении сайта передаются пользователем добровольно, к ним могут относиться:
        имя, фамилия, отчество, номера телефонов, адреса электронной почты, адреса для доставки товаров или
        оказания услуг, реквизиты компании, которую представляет пользователь, должность в компании, которую
        представляет пользователь, аккаунты в социальных сетях; поля форм могут запрашивать и иные данные.
      </p>
      <p>
        Эти данные собираются в целях оказания услуг, связи с пользователем или иной активности пользователя
        на сайте, а также, чтобы отправлять пользователям информацию, которую они согласились получать.
      </p>
      <p>
        Мы не проверяем достоверность оставляемых данных, однако не гарантируем качественного исполнения
        заказов или обратной связи с нами при некорректных данных.
      </p>
      <p>
        Данные собираются имеющимися на сайте формами для заполнения (например, регистрации, оформления
        заказа, подписки, оставления отзыва, обратной связи и иными).
      </p>
      <p>
        Формы, установленные на сайте, могут передавать данные как напрямую на сайт, так и на сайты сторонних
        организаций (скрипты сервисов сторонних организаций).
      </p>
      <p>
        Также данные могут собираться через технологию cookies (куки) как непосредственно сайтом, так и
        скриптами сервисов сторонних организаций. Эти данные собираются автоматически, отправку этих данных
        можно запретить, отключив cookies (куки) в браузере, в котором открывается сайт.
      </p>

      <h4>Не персональные данные</h4>
      <p>
        Кроме персональных данных при посещении сайта собираются не персональные данные, их сбор происходит
        автоматически веб-сервером, на котором расположен сайт, средствами CMS (системы управления сайтом),
        скриптами сторонних организаций, установленными на сайте. К данным, собираемым автоматически,
        относятся: IP адрес и страна его регистрации, имя домена, с которого вы к нам пришли, переходы
        посетителей с одной страницы сайта на другую, информация, которую ваш браузер предоставляет
        добровольно при посещении сайта, cookies (куки), фиксируются посещения, иные данные, собираемые
        счетчиками аналитики сторонних организаций, установленными на сайте.
      </p>
      <p>
        Эти данные носят неперсонифицированный характер и направлены на улучшение обслуживания клиентов,
        улучшения удобства использования сайта, анализа посещаемости.
      </p>

      <h3>Предоставление данных третьим лицам</h3>
      <p>
        Мы не раскрываем личную информацию пользователей компаниям, организациям и частным лицам, не связанным
        с нами. Исключение составляют случаи, перечисленные ниже.
      </p>

      <h4>Данные пользователей в общем доступе</h4>
      <p>
        Персональные данные пользователя могут публиковаться в общем доступе в соответствии с функционалом
        сайта, например, при оставлении отзывов, может публиковаться указанное пользователем имя, такая
        активность на сайте является добровольной, и пользователь своими действиями дает согласие на такую
        публикацию.
      </p>
      <h4>По требованию закона</h4>
      <p>
        Информация может быть раскрыта в целях воспрепятствования мошенничеству или иным противоправным
        действиям; по требованию законодательства и в иных случаях, предусмотренных законом.
      </p>

      <h4>Для оказания услуг, выполнения обязательств</h4>
      <p>
        Пользователь соглашается с тем, что персональная информация может быть передана третьим лицам в целях
        оказания заказанных на сайте услуг, выполнении иных обязательств перед пользователем. К таким лицам,
        например, относятся курьерская служба, почтовые службы, службы грузоперевозок и иные.
      </p>

      <h4>Сервисам сторонних организаций, установленным на сайте</h4>
      <p>
        На сайте могут быть установлены формы, собирающие персональную информацию других организаций, в этом
        случае сбор, хранение и защита персональной информации пользователя осуществляется сторонними
        организациями в соответствии с их политикой конфиденциальности.
      </p>
      <p>
        Сбор, хранение и защита полученной от сторонней организации информации осуществляется в соответствии с
        настоящей политикой конфиденциальности.
      </p>

      <h3>Как мы защищаем вашу информацию</h3>
      <p>
        Мы принимаем соответствующие меры безопасности по сбору, хранению и обработке собранных данных для
        защиты их от несанкционированного доступа, изменения, раскрытия или уничтожения, ограничиваем нашим
        сотрудникам, подрядчикам и агентам доступ к персональным данным, постоянно совершенствуем способы
        сбора, хранения и обработки данных, включая физические меры безопасности, для противодействия
        несанкционированному доступу к нашим системам.
      </p>

      <h3>Ваше согласие с этими условиями</h3>
      <p>
        Используя этот сайт, вы выражаете свое согласие с этой политикой конфиденциальности. Если вы не
        согласны с этой политикой, пожалуйста, не используйте наш сайт. Ваше дальнейшее использование сайта
        после внесения изменений в настоящую политику будет рассматриваться как ваше согласие с этими
        изменениями.
      </p>

      <h3>Отказ от ответственности</h3>
      <p>
        Политика конфиденциальности не распространяется ни на какие другие сайты и не применима к веб-сайтам
        третьих лиц, которые могут содержать упоминание о нашем сайте и с которых могут делаться ссылки на
        сайт, а также ссылки с этого сайта на другие сайты сети Интернет. Мы не несем ответственности за
        действия других веб-сайтов.
      </p>

      <h3>Изменения в политике конфиденциальности</h3>
      <p>
        Мы имеем право по своему усмотрению обновлять данную политику конфиденциальности в любое время. В этом
        случае мы опубликуем уведомление на главной странице нашего сайта. Мы рекомендуем пользователям
        регулярно проверять эту страницу для того, чтобы быть в курсе любых изменений о том, как мы защищаем
        информацию пользователях, которую мы собираем. Используя сайт, вы соглашаетесь с принятием на себя
        ответственности за периодическое ознакомление с политикой конфиденциальности и изменениями в ней.
      </p>

      <h3>Как с нами связаться</h3>
      <p>
        Если у вас есть какие-либо вопросы о политике конфиденциальности, использованию сайта или иным
        вопросам, связанным с сайтом, свяжитесь с нами по тел.
        <a href="tel:+7(499)472-19-78">+7 (499) 472-19-78</a>
        <span> или </span>
        <a href="mailto:info@dalli-service.com">info@dalli-service.com</a>
      </p>
    </div>
  );
  return MediaContent(content, <Content block={content} />);
};

export default PagePolicy;
