import { useEffect } from 'react';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import './ButtonToggleLang.scss';
import { Props } from './interfaces';
import { useNavigate } from 'react-router-dom';
import { EnumUrlsPages } from '../../services/Enum';

const ButtonToggleLang = ({ lang, langLoaded, currentCaseIds, color = 'dark' }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    const en = /\/(en)\/[A-Za-z0-9-_]/.exec(window.location.href);
    const enMain = /\/(en)/.exec(window.location.href);

    if (en || enMain) {
      langLoaded('En');
    } else {
      langLoaded('Ru');
    }
  }, []);

  const toggleTheme = () => {
    const isCase = /\/case\/([0-9]+)/.exec(window.location.href);

    if (lang === 'Ru') {
      langLoaded('En');

      let url = `${window.location.pathname}${window.location.search}`;

      if (isCase) {
        url = `${EnumUrlsPages.case}/${currentCaseIds?.eng}${window.location.search}`;
      }

      navigate(`/en${url}`);
    } else {
      langLoaded('Ru');

      let url = `${window.location.pathname.slice(3)}${window.location.search}`;

      if (isCase) {
        url = `${EnumUrlsPages.case}/${currentCaseIds?.web}${window.location.search}`;
      }

      navigate(url);
    }
  };

  const hoverEffects = {
    onMouseEnter: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      (e.target as HTMLElement).classList.add('lang__name_start-animation');
      (e.target as HTMLElement).classList.remove('lang__name_end-animation');
    },
    onMouseLeave: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      (e.target as HTMLElement).classList.remove('lang__name_start-animation');
      (e.target as HTMLElement).classList.add('lang__name_end-animation');
    },
  };

  return (
    <div className="lang" {...hoverEffects}>
      <p className={`lang__name${color ? ` lang__name_${color}` : ''}`} onClick={toggleTheme}>
        {lang === 'En' ? 'Ru' : 'En'}
      </p>
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(ButtonToggleLang));
