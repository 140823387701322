import * as React from 'react';
import { Props } from './interfaces';
import Footer from '../Footer/Footer';
import Content from '../Content/Content';
import Header from '../Header/Header/Header';
import Alert from '../Alert/Alert';
import Cookies from '../Cookies/Cookies';

const Layout = ({ page, noFooter = false, noHeader = false, colorHeader = 'dark' }: Props) => {
  return (
    <div className="app-container">
      <Alert />
      <Cookies />

      {!noHeader && <Content noLazy block={<Header color={colorHeader} />} />}
      <div className="app-content">{page}</div>
      {!noFooter && <Content noLazy block={<Footer />} />}
    </div>
  );
};

export default Layout;
