import { Props } from './interfaces';
import './AboutBlock.scss';
import { useRef } from 'react';
import { useGSAP } from '@gsap/react';
import { goAnimationAbout } from './functions';
import { Link } from 'react-router-dom';
import { EnumUrlsPages } from '../../services/Enum';
import { Sticky } from '../../components/Sticky/Sticky';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const AboutBlock = ({ images, lang }: Props) => {
  
  const container: any = useRef();

  useGSAP(
    () => {
      goAnimationAbout(container.current);
    },
    { scope: container }
  );

  return (
    <Sticky>
      <div className="about-block" ref={container}>
        <div className="about-block__container">
          <div className="about-block__center">
          {/* <img src={require("./img/1.png")} className="about-block__image" alt="" />
          <img src={require("./img/1.png")} className="about-block__image" alt="" />
          <img src={require("./img/1.png")} className="about-block__image" alt="" />
          <img src={require("./img/1.png")} className="about-block__image" alt="" />
          <img src={require("./img/1.png")} className="about-block__image" alt="" />
          <img src={require("./img/1.png")} className="about-block__image" alt="" />
          <img src={require("./img/1.png")} className="about-block__image" alt="" />
          <img src={require("./img/1.png")} className="about-block__image" alt="" />
          <img src={require("./img/1.png")} className="about-block__image" alt="" />
          <img src={require("./img/1.png")} className="about-block__image" alt="" />
          <img src={require("./img/1.png")} className="about-block__image" alt="" />
          <img src={require("./img/1.png")} className="about-block__image" alt="" />
          <img src={require("./img/1.png")} className="about-block__image" alt="" />
          <img src={require("./img/1.png")} className="about-block__image" alt="" /> */}
            {images.map((image, i) => (
              <img src={image} className="about-block__image" alt="" key={i} />
            ))}
          </div>
        </div>

        <div className="about-block__container-info">
          <div className="about-block__info">
            <h2 className="about-block__title">Main Target Group</h2>
            <div className="about-block__link-wrapper">
              <Link to={EnumUrlsPages.agency} className="about-block__link"></Link>
              <p className="about-block__link-text">{ lang === "En" ? "Agency" : "Агентство" }</p>

              <div className="about-block__green-bg" />
              <div className="about-block__dark-bg" />
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  )
};
