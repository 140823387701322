const AlertLoaded = (data: any) => ({ type: 'ALERT_LOADED', payload: data });
const ArticleLoaded = (data: any) => ({ type: 'ARTICLE_LOADED', payload: data });
const WidthWindowLoaded = (data: any) => ({ type: 'WIDTH_WINDOW_LOADED', payload: data });
const OffsetContentLoaded = (data: any) => ({ type: 'OFFSET_CONTENT_LOADED', payload: data });
const LangLoaded = (data: any) => ({ type: 'LANG_LOADED', payload: data });
const CurrentCaseIdsLoaded = (data: any) => ({ type: 'CURRENT_CASE_IDS_LOADED', payload: data });

export {
  AlertLoaded,
  ArticleLoaded,
  WidthWindowLoaded,
  OffsetContentLoaded,
  LangLoaded,
  CurrentCaseIdsLoaded,
};
