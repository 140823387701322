import './Cursor.scss';

export class Cursor {
  target = { x: 0.5, y: 0.5 }; //координаты указателя мыши
  cursor = { x: 0.5, y: 0.5 }; //координаты нового курсора
  speed = 0.3; //скорость курсора
  raf: any;

  constructor() {
    this.init();
  }

  init() {
    this.onMouseMove = this.onMouseMove.bind(this);
    this.render = this.render.bind(this);

    window.addEventListener('mousemove', this.onMouseMove);
    this.raf = requestAnimationFrame(this.render);
  }

  onMouseMove(e: any) {
    this.target.x = e.clientX / window.innerWidth;
    this.target.y = e.clientY / window.innerHeight;
    if (!this.raf) this.raf = requestAnimationFrame(this.render);

    const cursor = document.querySelector('.cursor') as HTMLElement;

    if (e.target.closest('.card-link')) {
      document.documentElement.style.setProperty('--scale', '2');
      cursor.style.opacity = '1';
      return;
    }
    document.documentElement.style.setProperty('--scale', '1');

    if (e.target.closest('.page-projects__filters')) {
      cursor.style.opacity = '0';
      return;
    }
    cursor.style.opacity = '1';
  }

  render() {
    this.cursor.x = lerp(this.cursor.x, this.target.x, this.speed);
    this.cursor.y = lerp(this.cursor.y, this.target.y, this.speed);
    document.documentElement.style.setProperty('--cursor-x', this.cursor.x.toString());
    document.documentElement.style.setProperty('--cursor-y', this.cursor.y.toString());

    const delta = Math.sqrt(
      Math.pow(this.target.x - this.cursor.x, 2) + Math.pow(this.target.y - this.cursor.y, 2)
    );
    if (delta < 0.001) {
      cancelAnimationFrame(this.raf);
      this.raf = null;
      return;
    }

    this.raf = requestAnimationFrame(this.render);
  }

  destroy() {
    window.removeEventListener('mousemove', this.onMouseMove);
  }
}

const lerp = (a: number, b: number, n: number) => (1 - n) * a + n * b;
