import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './FullScreenImage.scss';
import { baseURLModx } from '../../redux/services/APIRequestsModx';

export function FullScreenImage(image: any) {
  const containerRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(imageRef.current, {
        y: -120,
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
        },
      });
    }, containerRef);

    return () => ctx.revert();
  }, []);

  return (
    <div className="full-screen-image" ref={containerRef}>
      <img src={`${baseURLModx}/${image.image}`} alt="" ref={imageRef} />
    </div>
  );
}
