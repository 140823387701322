import React, { useEffect, useState } from 'react';
import { Props } from './interfaces';
import './HeaderMobPopup.scss';
import { Link } from 'react-router-dom';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../../redux/services/Imports';

import MenuHamburgerStub from '../MenuHamburger/MenuHamburgerStub';
import { MenuTopList, MenuTopListEn } from '../Header/constants';
import BaseLink from '../../BaseLink/BaseLink';
import ButtonToggleLang from '../../ButtonToggleLang/ButtonToggleLang';
import { LinkTg } from '../../../services/Constants';

const HeaderMobPopup = ({ closed, show, widthWindow, lang }: Props) => {
  const [menu, setMenu] = useState(MenuTopList);

  useEffect(() => {
    setMenu(lang === 'En' ? MenuTopListEn : MenuTopList);
  }, [lang]);

  return (
    <div className={`popup-header${show ? ' popup-header_open' : ' popup-header_hide'}`}>
      <div className="popup-header__header">
        <Link to={lang === 'En' ? '/en' : '/'} onClick={closed} className="popup-header__logo-link">
          <img src={require('./img/logo.svg').default} className="popup-header__logo" alt="logo" />
        </Link>

        <MenuHamburgerStub onClick={closed} />
      </div>

      <div className="popup-header__content">
        {menu.slice(0, -1).map((menu, i) => (
          <Link to={menu.link} key={i} onClick={closed}>
            <h4>{menu.text}</h4>
          </Link>
        ))}
      </div>

      <div className="popup-header__options">
        <BaseLink link={LinkTg} text="Telegram" color="white" />
        <ButtonToggleLang color="white" />
      </div>
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(HeaderMobPopup));
