import React, { useState } from 'react';
import { Swiper, SwiperSlide, Autoplay, Pagination, TypeSwiper } from '../../services/ImportsSwiper';
import { Props } from './interfaces';
import './SliderServices.scss';
import PaginationBlock from '../Pagination/Pagination';
import { baseURLModx } from '../../redux/services/APIRequestsModx';

const SliderServices = ({ title, slides }: Props) => {
  const [numActiveSlide, setNumActiveSlide] = useState(1);

  const Changed = (current: TypeSwiper) => setNumActiveSlide(current.realIndex + 1);

  const [slider, setSlider] = useState<TypeSwiper>();
  const settings = {
    autoplay: false,
    speed: 500,
    loop: true,
    modules: [Autoplay, Pagination],
    slidesPerView: 1,
    onSlideChange: Changed,
    className: 'slider-services',
    spaceBetween: 20,
    autoHeight: true,
  };

  const prevSlide = () => {
    if (slider) {
      slider.slidePrev(500, false);
    }
  };
  const nextSlide = () => {
    if (slider) {
      slider.slideNext(500, false);
    }
  };

  return slides.length ? (
    <div className="services-slider-block">
      {title ? <h3 className="services-slider-block__title">{title}</h3> : null}

      <Swiper {...settings} onInit={(slider: TypeSwiper) => setSlider(slider)}>
        {slides.map(({ name, description, image }, i) => (
          <SwiperSlide key={i}>
            <div className="slider-services__slide" key={i}>
              <h4 className="slider-services__title" dangerouslySetInnerHTML={{ __html: name }}></h4>
              <p
                className="body2 slider-services__text"
                dangerouslySetInnerHTML={{ __html: description }}
              ></p>
              {image ? (
                <img src={`${baseURLModx}/${image}`} className="slider-services__image" alt="" />
              ) : null}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="slider-services__dots">
        <PaginationBlock current={numActiveSlide} all={slides.length} />
        <div className="slider-services__arrow slider-services__arrow_prev" onClick={prevSlide}></div>
        <div className="slider-services__arrow slider-services__arrow_next" onClick={nextSlide}></div>
      </div>
    </div>
  ) : null;
};

export default SliderServices;
