import { MutableRefObject, useEffect, useRef } from 'react';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import './ScrollSpace.scss';
import './ScrollSpace-media.scss';
import CardLink from '../CardLink/CardLink';
import { Props } from './interfaces';
import { ICardLink } from '../CardLink/interfaces';
import { Cursor } from '../Cursor/Cursor';

let idTimer: any;

const ScrollSpace = ({ list, columns, widthWindow }: Props) => {
  const space: MutableRefObject<any> = useRef();
  const container: MutableRefObject<any> = useRef();

  const mousemove = (e: any) => {
    if (idTimer) {
      idTimer = 0;
      clearTimeout(idTimer);
      return;
    }

    idTimer = setTimeout(() => {
      const s = space.current;
      const xx = (e.clientX - s.getBoundingClientRect().x) / s.clientWidth;
      const yy = (e.clientY - s.getBoundingClientRect().y) / s.clientHeight;

      s.scroll({
        top: (s.scrollHeight - s.clientHeight) * yy,
        left: (s.scrollWidth - s.clientWidth) * xx,
        behavior: 'smooth',
      });
    }, 200);
  };

  useEffect(() => {
    const s = space.current;
    s.scroll({
      top: (s.scrollHeight - s.clientHeight) * 0.5,
      left: (s.scrollWidth - s.clientWidth) * 0.5,
      behavior: 'smooth',
    });

    const cursor = widthWindow > 720 ? new Cursor() : null;
    return () => {
      cursor?.destroy();
    };
  }, []);

  useEffect(() => {
    const cursor = widthWindow > 720 ? new Cursor() : null;
    return () => {
      cursor?.destroy();
    };
  }, [widthWindow]);

  const wheel = (e: any) => {
    e.preventDefault();
  };
  useEffect(() => {
    container.current?.addEventListener('wheel', wheel, { passive: false });
    return () => window.removeEventListener('resize', wheel);
  }, []);

  const rows = Math.round(list.length / columns) || 1;

  const arr: ICardLink[][] = SubArrs(list, rows);

  return (
    <div className="scroll-space" ref={space}>
      <div className="cursor" />
      <div
        className="scroll-space__container"
        onMouseMove={mousemove}
        ref={container}
        style={{
          width: `${arr.length * 23.96}vw`,
          height: `${rows * 35 + 15}vw`,
        }}
      >
        {arr.map((column, i) => (
          <div className="scroll-space__column" key={i}>
            {column.map((item, idx) => (
              <CardLink {...item} key={idx}></CardLink>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(ScrollSpace));

const SubArrs = (arr: ICardLink[], length: number) => {
  const arrNew: any = [];

  for (let i = 0; i <= arr.length - 1; i = i + length) {
    arrNew.push(arr.slice(i, i + length));
  }

  return arrNew;
};
