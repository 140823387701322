import { IMenu } from '../../../redux/initState/InterfacesState';
import { EnumUrlsPages, EnumUrlsPagesEn } from '../../../services/Enum';

export const MenuTopList: IMenu[] = [
  { text: 'Проекты', link: EnumUrlsPages.projects, intoLink: false, hash: false },
  { text: 'Услуги', link: EnumUrlsPages.services, intoLink: false, hash: false },
  { text: 'Агентство', link: EnumUrlsPages.agency, intoLink: false, hash: false },
  { text: 'Контакты', link: EnumUrlsPages.contacts, intoLink: false, hash: false },
  { text: 'Telegram', link: 'https://t.me/mtg_agency', intoLink: true, hash: false, iconLink: true },
];

export const MenuTopListEn: IMenu[] = [
  { text: 'Projects', link: EnumUrlsPagesEn.projects, intoLink: false, hash: false },
  { text: 'Services', link: EnumUrlsPagesEn.services, intoLink: false, hash: false },
  { text: 'Agency', link: EnumUrlsPagesEn.agency, intoLink: false, hash: false },
  { text: 'Contacts', link: EnumUrlsPagesEn.contacts, intoLink: false, hash: false },
  { text: 'Telegram', link: 'https://t.me/mtg_agency', intoLink: true, hash: false, iconLink: true },
];
