import React from 'react';
import './TelTo.scss';
import { Props } from './interfaces';

const Tel = ({ phone, className = 'buttonM' }: Props) => {
  return (
    <a
      className={`${className} tel-to`}
      href={`tel:${phone}`}
      target="_blank"
      dangerouslySetInnerHTML={{ __html: phone }}
    />
  );
};

export default Tel;
