import React from 'react';
import './Title.scss';
import './Title-media.scss';
import { Props } from './interfaces';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';

const Title = ({
  title,
  shortText,
  text,
  center,
  gradient,
  color,
  marginBottom = 0,
  h1,
  widthWindow,
}: Props) => {
  const mb: string =
    widthWindow > 720 && widthWindow < 1439 ? `${marginBottom / 14.4}vw` : `${marginBottom}px`;

  const classTitle = `title${gradient ? ' title_gradient' : ''}${color ? ` title_${color}` : ''}`;
  const classText = `body1 title__text${center ? 'title__text_center' : 'title__text_start'}${
    color ? ` title_text_${color}` : ''
  }`;

  const tagTitle = h1 ? (
    <h1
      className={classTitle}
      style={{
        textAlign: center ? 'center' : 'start',
        marginBottom: mb || '0',
        marginLeft: center ? 'auto' : 0,
      }}
      dangerouslySetInnerHTML={{ __html: title }}
    ></h1>
  ) : (
    <h2
      className={classTitle}
      style={{
        textAlign: center ? 'center' : 'start',
        marginBottom: mb || '0',
        marginLeft: center ? 'auto' : 0,
      }}
      dangerouslySetInnerHTML={{ __html: title }}
    ></h2>
  );

  return (
    <>
      <div className="title-container">
        {tagTitle}
        {shortText ? <p className="body1">{shortText}</p> : null}
      </div>
      {text ? <p className={classText}>{text}</p> : null}
    </>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(Title));
