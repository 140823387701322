import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { defer, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IModxAgencyPage,
  IModxCasePage,
  IModxContactsPage,
  IModxMainPage,
  IModxProjectsPage,
  IModxServicesPage,
} from './InterfacesModx';

const { REACT_APP_URL_API_MODX } = process.env;
export const baseURLModx = `https://${REACT_APP_URL_API_MODX}`;

class APIRequests {
  axiosConfig: AxiosRequestConfig = {
    baseURL: `${baseURLModx}/api`,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  axiosConfigWithAuthorization: AxiosRequestConfig = Object.assign({}, this.axiosConfig, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  setAxiosConfigWithAuthorization = (token: string) => {
    if (this.axiosConfigWithAuthorization.headers) {
      this.axiosConfigWithAuthorization.headers.Authorization = `Bearer ${token}`;
      this.axiosInstanceWithAuthorization = this.axiosInit(this.axiosConfigWithAuthorization);
    }
  };

  axiosInit = (config: AxiosRequestConfig): AxiosInstance => {
    const axiosInstance = axios.create(config);
    return axiosInstance;
  };

  axiosInstance = this.axiosInit(this.axiosConfig);
  axiosInstanceWithAuthorization = this.axiosInit(this.axiosConfigWithAuthorization);

  funcGet = (inst: AxiosInstance, url: string, queryParams?: object) => {
    return defer(() => inst.get(url, { params: queryParams })).pipe(map((result) => result.data));
  };

  get = <T>(url: string, queryParams?: object): Observable<T> => {
    return this.funcGet(this.axiosInstance, url, queryParams);
  };

  getWithAuthorization = <T>(url: string, queryParams?: object): Observable<T> => {
    return this.funcGet(this.axiosInstanceWithAuthorization, url, queryParams);
  };

  post = <T>(url: string, queryParams: object): Observable<T> => {
    return defer(() => this.axiosInstance.post(url, queryParams)).pipe(map((result) => result.data));
  };

  postBody = <T>(url: string, queryParams: object): Observable<T> => {
    return defer(() =>
      this.axiosInstanceWithAuthorization.post(url, queryParams, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    ).pipe(map((result) => result.data));
  };

  delete = <T>(url: string): Observable<T> => {
    return defer(() => this.axiosInstanceWithAuthorization.delete(url)).pipe(map((result) => result.data));
  };

  put = <T>(url: string, queryParams: object): Observable<T> => {
    return defer(() => this.axiosInstanceWithAuthorization.put(url, queryParams)).pipe(
      map((result) => result.data)
    );
  };

  //-----------------------------------------------------------------------------

  sendEmail = ({ data }: { data: FormData }) => this.postBody<IModxMainPage>('/mail', data);

  getMainPage = ({ en }: { en?: boolean }) => this.get<IModxMainPage>(`${en ? '/en' : ''}/main`);

  getAgencyPage = ({ en }: { en?: boolean }) => this.get<IModxAgencyPage>(`${en ? '/en' : ''}/agency`);

  getServicesPage = ({ en }: { en?: boolean }) => this.get<IModxServicesPage>(`${en ? '/en' : ''}/services`);

  getProjectsPage = ({ en }: { en?: boolean }) => this.get<IModxProjectsPage>(`${en ? '/en' : ''}/cases`);

  getCasePage = ({ id, en }: { id: string; en?: boolean }) =>
    this.get<IModxCasePage>(`${en ? '/en' : ''}/cases/${id}`);

  getContacts = ({ en }: { en?: boolean }) => this.get<IModxContactsPage>(`${en ? '/en' : ''}/contacts`);
}

export const APIModx = new APIRequests();
