import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

export const goAnimationMainProjects = () => {
  ScrollTrigger.normalizeScroll(false);

  const halfWindowHeaght = window.screen.height / 2;

  gsap.registerPlugin(ScrollTrigger);
  gsap.to('.parallax-title__value', {
    scrollTrigger: {
      scrub: true,
      // markers: true,
      trigger: '.parallax-title__value',
      start: () => `${halfWindowHeaght} 100%`,
      end: () => `+=${halfWindowHeaght} 100%`,
    },
    duration: 3,
    opacity: 0,
  });
};
