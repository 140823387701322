import React from 'react';
import './ThreeColumGallery.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { baseURLModx } from '../../redux/services/APIRequestsModx';

type ThreeColumGallery = {
  image1: string;
  image2: string;
  image3: string;
};

export const ThreeColumImages = ({image1, image2, image3}: ThreeColumGallery) => {
  const sliderBreakpoints = {
    768: {
      slidesPerView: 3,
    },
    426: {
      slidesPerView: 2,
    },
  };

  const sliderSettings = {
    modules: [Pagination],
    spaceBetween: 20,
    slidesPerView: 1.15,
    breakpoints: { ...sliderBreakpoints },
  };

  return (
    <div className="stories">
      <div className="stories__container">
          <Swiper {...sliderSettings} className="stories">
              <SwiperSlide className="stories__image">
                <img src={`${baseURLModx}/${image1}`} alt="" />
              </SwiperSlide>
              <SwiperSlide className="stories__image">
                <img src={`${baseURLModx}/${image2}`} alt="" />
              </SwiperSlide>
              <SwiperSlide className="stories__image">
                <img src={`${baseURLModx}/${image3}`} alt="" />
              </SwiperSlide>
          </Swiper>
      </div>
    </div>
  );
};

export default ThreeColumImages;
