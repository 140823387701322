import React, { useEffect, useState } from 'react';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import { Swiper, SwiperSlide } from '../../services/ImportsSwiper';
import { ILogoPartner, Props } from './interfaces';
import './SliderLogos.scss';
import './SliderLogos-media.scss';
import { settings, settings2 } from './constants';

const SliderLogos = ({ slidesLogo, logosPartners, padding, widthWindow, offsetContent }: Props) => {
  const [logosList, setLogosList] = useState<ILogoPartner[]>([]);
  useEffect(() => {
    if (slidesLogo) {
      setLogosList(slidesLogo);
    } else {
      setLogosList(logosPartners);
    }
  }, [slidesLogo, logosPartners]);

  const [slides, setSlides] = useState<ILogoPartner[][]>([[], []]);
  useEffect(() => {
    const countLogo = logosList.length;
    const lengthHalf = Math.ceil(countLogo / 2);

    if (countLogo < 7) {
      setSlides([logosList.slice(0), []]);
    } else {
      setSlides([logosList.slice(0, lengthHalf), logosList.slice(lengthHalf)]);
    }
  }, [logosList]);

  const [countSlides, setCountSlides] = useState(Math.ceil(widthWindow / 320));
  useEffect(() => {
    if (widthWindow > 720) {
      setCountSlides(Math.ceil(widthWindow / 320));
    } else {
      setCountSlides(Math.ceil(widthWindow / 180));
    }
  }, [widthWindow]);

  const stylePadding = padding ? { padding } : {};
  const stylesBlock = { ...stylePadding /*transform: `translateX(-${offsetContent}px)`*/ };

  return (
    <div className="block-logos" style={stylesBlock}>
      {slides[0].length ? (
        <Swiper {...settings} slidesPerView={countSlides}>
          {slides[0]?.map((logo, i) => (
            <SwiperSlide key={i}>
              <Slide {...logo} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : null}
      {slides[1].length ? (
        <Swiper {...settings2} slidesPerView={countSlides}>
          {slides[1]?.map((logo, i) => (
            <SwiperSlide key={i}>
              <Slide {...logo} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : null}
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(SliderLogos));

const Slide = ({ image, alt }: ILogoPartner) => (
  <div className="slider-logos__slide">
    <img src={/*`${baseURLModx}/${image}`*/ image} className="slider-logos__logo" alt={alt}></img>
  </div>
);
