import { useState, createRef, RefObject } from 'react';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import './BlockVideo.scss';
import './BlockVideo-media.scss';
import { HandlerEndedVideo, HandlerPlayPauseVideo } from './functions';
import { Props } from './interfaces';
import { baseURLModx } from '../../redux/services/APIRequestsModx';

const BlockVideo = ({
  title,
  video,
  poster,
  height,
  heightMob,
  autoPlay = false,
  controls = false,
  loader = false,
  fullScreen = false,
  fullText,
  hiddenPlay = false,
  content,
  widthWindow,
}: Props) => {
  const [play, setPlay] = useState(false);
  const player: RefObject<HTMLVideoElement> = createRef();
  const [settingsAutoPlay, setSettingsAutoPlay] = useState(autoPlay);

  const onPause = () => setPlay(false);

  const heightVideo = fullScreen ? 'auto' : widthWindow > 720 ? height : heightMob;

  return (
    <>
      <div className={`block-video${fullScreen ? ' block-video_full-width' : ''}`}>
        {title ? <h2 className="block-video__title">{title}</h2> : null}

        <div className="block-video__container" style={{ height: heightVideo }}>
          <video
            ref={player}
            width="100%"
            autoPlay={autoPlay}
            loop={autoPlay}
            muted={autoPlay}
            playsInline={autoPlay}
            className={`block-video__video${fullScreen ? ' block-video__video_full-height' : ''}`}
            poster={loader ? require('./img/loader.gif') : poster}
            onClick={!autoPlay ? HandlerPlayPauseVideo({ play, player, setPlay }) : () => { }}
            onEnded={HandlerEndedVideo({ player, setPlay, setSettingsAutoPlay, poster })}
            controls={controls}
            onPlay={autoPlay ? HandlerPlayPauseVideo({ play, player, setPlay }) : () => { }}
            onPause={autoPlay ? onPause : () => { }}
          >
            <source src={`${baseURLModx}${video}`} type="video/mp4" />
          </video>
          {!play && !hiddenPlay ? (
            <div className="block-video__play-block">
              {fullText ? <h2 dangerouslySetInnerHTML={{ __html: fullText }} /> : null}
              <div className="block-video__play" />
            </div>
          ) : null}
        </div>
      </div>

      {fullScreen ? <div className="block-video__full-screen">{content}</div> : null}
    </>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(BlockVideo));
