import * as React from 'react';
import './BlackBgSectionCards.scss';
import {baseURLModx} from '../../redux/services/APIRequestsModx';

type TextPropsType = {
  text: string;
  icon: string;
};

type BlackBgSectionPropsType = {
  title?: string;
  subtitle?: string;
  color?: string;
  text?: Array<TextPropsType>;
};

export function BlackBgSectionCards({title, subtitle, text, color}: BlackBgSectionPropsType) {
  return (
    <div className="blac-bg-section" style={{backgroundColor: `${color}`}}>
      <div className="content">
        <div className="blac-bg-section__head">
          <h2 className="blac-bg-section__title">{title}</h2>
          <p className="blac-bg-section__subtitle">{subtitle}</p>
        </div>
        {text ? (
          <div className="blac-bg-section__cards-block cards-block">
            {text.map((item, i) => (
              <div className="cards-block__card" key={i}>
                <div className="cards-block__img" style={{backgroundImage: `url(${baseURLModx}/${item.icon})`}}></div>
                <div className="cards-block__text">
                  <span>{item.text}</span>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}
