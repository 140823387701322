import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { ITabCategory } from '../../components/Tabs/interfaces';

export const goAnimation = () => {
  ScrollTrigger.normalizeScroll(false);

  gsap.registerPlugin(ScrollTrigger);

  setTimeout(() => {
    gsap.to('.page-case__image', {
      scrollTrigger: {
        scrub: true,
        // markers: true,
        trigger: '.page-case',
        endTrigger: 'page-case__image',
        start: () => `0px 0%`,
        end: () => `1300 10%`,
      },
      duration: 3,
      width: '100vw',
      height: '100dvh',
      bottom: '0',
    });

    const settingsForTexts = {
      scrollTrigger: {
        scrub: true,
        // markers: true,
        trigger: '.page-case',
        endTrigger: 'page-case__image',
        start: () => `0 0%`,
        end: () => `1300 10%`,
      },
      duration: 3,
      color: '#fff',
      stroke: '#fff',
    };

    gsap.to(
      '.page-case__title, .page-case__text, .page-case__hours, .lang__name, .menu-top__link, svg path',
      settingsForTexts
    );
  }, 200);
};

export const formatFilters = (filters: string[]): ITabCategory[] => {
  const result: ITabCategory[] = [];

  filters.forEach((tag) => {
    result.push({
      text: tag,
      tab: tag,
    });
  });

  return result;
};
