import React, { useState } from 'react';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import { Swiper, SwiperSlide, Autoplay, Pagination, TypeSwiper } from '../../services/ImportsSwiper';

import CardTeam from '../../components/CardTeam/CardTeam';
import './SliderTeam.scss';
import './SliderTeam-media.scss';
import { Props } from './interfaces';
import { MediaContent } from '../../services/GlobalFunctions';

const SliderTeam = ({ slides, widthWindow }: Props) => {
  const [numActiveSlide, setNumActiveSlide] = useState(0);

  const Changed = (current: TypeSwiper) => setNumActiveSlide(current.activeIndex);

  const [slider, setSlider] = useState<TypeSwiper>();

  const settings = {
    initialSlide: numActiveSlide,
    autoplay: false,
    speed: 500,
    loop: true,
    modules: [Autoplay, Pagination],
    onSlideChange: Changed,
    spaceBetween: 20,
    className: 'slider-team',
    slidesPerView: widthWindow > 720 ? 3 : 1,
    slideToClickedSlide: true,
  };

  const prevSlide = () => {
    if (slider) {
      slider.slidePrev(500, false);
    }
  };
  const nextSlide = () => {
    if (slider) {
      slider.slideNext(500, false);
    }
  };

  return (
    <div className="slider-team">
      <Swiper {...settings} onInit={(slider: TypeSwiper) => setSlider(slider)}>
        {slides.map((post, i) => (
          <SwiperSlide key={i}>
            <CardTeam {...post} key={i} />
          </SwiperSlide>
        ))}

        {MediaContent(
          <div className="slider-team__dots">
            <div className="slider-team__arrow slider-team__arrow_prev" onClick={prevSlide}></div>
            <div className="slider-team__arrow slider-team__arrow_next" onClick={nextSlide}></div>
          </div>,
          null
        )}
      </Swiper>
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(SliderTeam));
