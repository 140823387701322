import React, { useRef, useEffect } from 'react';
import './BlackBgSection.scss';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { baseURLModx } from '../../redux/services/APIRequestsModx';

gsap.registerPlugin(ScrollTrigger);

type TextPropsType = {
  text: string;
};

type BlackBgSectionPropsType = {
  title?: string;
  subtitle?: string;
  image1?: string;
  colour?: string;
  text?: Array<TextPropsType>;
};

export function BlackBgSection({ title, subtitle, text, colour, image1 }: BlackBgSectionPropsType) {
  const containerRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      if (!containerRef.current || !imageRef.current) return;

      gsap.to(imageRef.current, {
        y: -120,
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
        },
      });
    }, containerRef);

    return () => ctx.revert();
  }, []);
  return (
    <>
      <div ref={containerRef} className="parallax-image">
        <img ref={imageRef} src={`${baseURLModx}/${image1}`} alt="" />
      </div>
      <div className="blac-bg-section" style={{ background: `${colour}` }}>
        <div className="content">
          <div className="blac-bg-section__head">
            <h2 className="blac-bg-section__title">{title}</h2>
            <p className="blac-bg-section__subtitle">{subtitle}</p>
          </div>
          {text ? (
            <div className="blac-bg-section__list-block">
              <ol>
                {text.map((item, i) => (
                  <li key={i}>{item.text}</li>
                ))}
              </ol>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
